<template>
  <main class="antialiased">
    <teleport to="head">
      <meta name="title" :content="content.title + ' - Hasolu'" />
      <meta name="description" :content="content.description" />
      <meta name="robots" content="index, follow" />

      <meta name="language" content="English" />
      <meta name="author" content="hasolu" />
    </teleport>
    <Header
      class="fixed bg-white dark:bg-black z-10 sm:border-none border-b border-alphagray sm:dark:bg-transparent h-16 sm:bg-transparent top-0 "
    />
    <div class="container mt-24 sm:mt-36  h-screen mx-auto px-4 py-2">
      <div class=" h-auto w-full">
        <h1
          class=" text-5xl text-black dark:text-white  sm:text-8xl w-full font-hslBold"
        >
          {{ content.title }}
        </h1>
        <div
          class="mt-6 font-hsl text-base sm:text-xl text-black dark:text-white flex items-center"
        >
          <span class="sm:w-12 w-10 rounded-full p-[0.20rem] mr-3">
            <img
              draggable="false"
              class="rounded-full w-full transition-all  hover:ring ring-red-500  "
              src="https://avatars.githubusercontent.com/u/52406724?v=4"
            />
          </span>
          <span>{{ content.author }}</span>
          <span class="mx-2">·</span>
          <span class="text-gray-500 dark:text-hslgray">{{
            moment(content.date).format("MMM DD YYYY")
          }}</span>
        </div>
      </div>
      <div class="mt-12 pb-20 container   ">
        <article
          v-html="articletext"
          class="prose max-w-none mx-auto dark:text-white prose-lg sm:prose-2xl w-full"
        ></article>
      </div>
    </div>
  </main>
</template>
<script>
import axios from "axios";
import Header from "@/components/Header";
import moment from "moment";
import marked from "marked";

export default {
  data() {
    return {
      content: {},
      moment: null,
      articletext: null,
    };
  },
  components: { Header },
  created() {
    this.moment = moment;
  },
  computed: {},
  mounted() {
    axios
      .get(
        `${process.env.VUE_APP_API_URL}/articles/${this.$route.params.path}`,
        {
          headers: { "api-token": process.env.VUE_APP_API_TOKEN },
        }
      )
      .then((res) => {
        this.content = res.data;

        this.articletext = marked(this.content.content);
      });
  },
};
</script>
